import React, { useEffect, useState } from "react";
import { useChoicesContext, AutocompleteInputProps, useListContext } from "react-admin";
import { Autocomplete, Box, TextField } from "@mui/material";

interface CustomAutocompleteArrayInputProps extends AutocompleteInputProps {
  optionText?: string | ((choice: any) => string);
  optionValue?: string;
  choices?: any[];
  clearOnBlur?: boolean;
}

const CustomAutocompleteArrayInput: React.FC<CustomAutocompleteArrayInputProps> = ({
  source,
  label,
  clearOnBlur = false,
  validate,
  fullWidth = true,
  ...props
}) => {
  const { perPage, page, sort, setFilters, setPage, setPerPage, setSort, displayedFilters } = useListContext();
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  const { allChoices, setFilters: setFiltersForTags, displayedFilters: displayedTagsFilters } = useChoicesContext();

  useEffect(() => {
    setFiltersForTags({ q: inputValue }, displayedTagsFilters);
  }, [inputValue]);

  useEffect(() => {
    setFilters({ tags: selectedValues.map((item) => item.id) }, displayedFilters);
    setPage(page);
    setPerPage(perPage);
    setSort(sort);
  }, [selectedValues]);

  return (
    <Box width={fullWidth ? " 100%" : "auto"} minWidth={"200px"}>
      <Autocomplete
        multiple
        value={selectedValues}
        options={allChoices ? allChoices.filter((choice) => !selectedValues.some((item) => item.id === choice.id)) : []}
        getOptionLabel={(choice) => {
          return `${choice.tagTypeId?.name}: ${choice.value}`;
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        inputValue={inputValue}
        onInputChange={(_, newValue) => setInputValue(newValue)}
        renderInput={(params) => <TextField {...params} label={label || source} variant="filled" />}
        disableCloseOnSelect
        onChange={(e, v) => setSelectedValues(v)}
        clearOnBlur={clearOnBlur}
        size={"small"}
      />
    </Box>
  );
};

export default CustomAutocompleteArrayInput;
