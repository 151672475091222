import { useState } from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ShowButton,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import CustomAutocompleteArrayInput from "./AutocompleteArrayInput";

const filtersComponents = [
  <TextInput label="Search" source="name" alwaysOn />,
  <ReferenceArrayInput source="tags" reference="tags">
    <CustomAutocompleteArrayInput
      label="Tags"
      clearOnBlur={false}
      optionText={(record) => `${record.tagTypeId?.name}: ${record.value}`}
    />
  </ReferenceArrayInput>,
  <ReferenceInput source="albumId" reference="albums">
    <AutocompleteInput />
  </ReferenceInput>,
  <ReferenceInput source="composerId" reference="composers">
    <AutocompleteInput />
  </ReferenceInput>,
];

export const TrackList = () => {
  return (
    <List filters={filtersComponents} sort={{ field: "createdAt", order: "DESC" }} perPage={25}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <FunctionField
          title="File"
          render={(record) => {
            if (!record.fileUrl) {
              return null;
            }

            return (
              <audio controls src={record.lowQualityFileUrl} preload="none">
                <a href={record.lowQualityFileUrl}>Download audio</a>
              </audio>
            );
          }}
        />
        <ReferenceField source="albumId" reference="albums" />
        <ReferenceField source="composerId" reference="composers" />
        <ReferenceArrayField reference="tags" source="tags">
          <SingleFieldList>
            <FunctionField
              render={(record) => {
                return <ChipField record={{ name: `${record.tagTypeId?.name}: ${record.value}` }} source="name" />;
              }}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="createdAt" showTime />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
