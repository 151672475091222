import { Box, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { FunctionComponent, useMemo } from "react";
import {
  DateInput,
  FunctionField,
  ImageField,
  ImageInput,
  Labeled,
  required,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { TracksInput } from "../../components";
import { formatTimePlayer } from "../../utils";
import isUrl from "is-url";

interface AlbumFormInputsProps {
  isEdit?: boolean;
  isCreate?: boolean;
}

interface ISummaryResult {
  key: string;
  value: string;
}

const summaryFormatter = new Map<string, (value: any) => ISummaryResult | ISummaryResult[]>([
  ["composer", (value: string[]) => ({ key: "Composer", value: value.join(" | ") })],
  ["bpm", (value: string[]) => ({ key: "BPM", value: value.join(" | ") })],
  [
    "length",
    (value: number) => ({
      key: "Length",
      value: formatTimePlayer(value),
    }),
  ],
  [
    "tags",
    (value: Record<string, any>[]) =>
      value.map((el) => ({
        key: el?.tagTypeId?.name,
        value: el.value,
      })),
  ],
]);

export const AlbumFormInputs: FunctionComponent<AlbumFormInputsProps> = (props: AlbumFormInputsProps) => {
  const { isCreate } = props;

  const record = useRecordContext();

  const transformedRecord = useMemo(() => {
    let summary = null;
    if (record?.summary) {
      summary = Object.entries(record.summary).reduce((acc, curr) => {
        const [key, value] = curr;

        const formatter = summaryFormatter.get(key);
        if (!formatter) {
          acc[key] = null;
          return acc;
        }

        const data = formatter(value);

        if (Array.isArray(data)) {
          data.forEach((el) => {
            if (!acc[el.key]) {
              acc[el.key] = el.value;
            } else {
              acc[el.key] += ` | ${el.value}`;
            }
          });
        } else {
          acc[data.key] = data.value;
        }

        return acc;
      }, {});
    }

    return {
      ...record,
      tracks: record?.tracks?.map((el) => el._id),
      summary,
    };
  }, [record]);

  const validateUrl = (value) => {
    if (!value || isUrl(value)) {
      return undefined;
    }

    return "Url is not valid. Example: https://google.com";
  };

  return (
    <SimpleForm record={transformedRecord}>
      <TextInput source="name" isRequired validate={required()} />
      <TextInput fullWidth multiline source="description" isRequired validate={required()} />
      <TextInput fullWidth multiline source="shortDescription" />
      <DateInput source="releaseDate" validate={required()} />
      <TextInput source="videoUrl" validate={validateUrl} />
      <TextInput source="spotifyUrl" validate={validateUrl} />
      <TextInput source="soundCloudUrl" validate={validateUrl} />
      <TextInput source="bandcampUrl" validate={validateUrl} />
      <TextInput source="appleMusicUrl" validate={validateUrl} />
      <Box>
        {record && (
          <Labeled>
            <ImageField source="coverUrl" label="Current cover" />
          </Labeled>
        )}
        {record && (
          <Labeled>
            <FunctionField
              label="Summary"
              render={(localRecord) => {
                if (!localRecord.summary) {
                  return (
                    <div>
                      <Typography>Summary was not calculated</Typography>
                      <Typography>Please save album to calculate it</Typography>
                    </div>
                  );
                }

                return (
                  <Table>
                    <TableBody>
                      {Object.entries<string>(localRecord.summary).map(([key, value]) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>
                              <b>{key}</b>
                            </TableCell>
                            <TableCell>{value}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                );
              }}
            />
          </Labeled>
        )}
      </Box>
      <ImageInput source="cover" label="New cover" validate={isCreate && required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <TracksInput filter={{ albumId: null }} source="tracks" entityName="album" />
    </SimpleForm>
  );
};
