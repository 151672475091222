import { FunctionComponent } from "react";
import {
  ImageField,
  ImageInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreateContext,
  useEditContext,
} from "react-admin";
import { REFERENCE_ARRAY_INPUT_SX } from "../../constants";
import { DEAL_TYPE_SELECT_OPTIONS } from "../../constants/slider-deals.constants";
import { DealType } from "../../enums";
import { ClickOptionInput } from "./ClickOptionInput";
import { OptionalInputs } from "./OptionalInputs";
import { RichTextInput } from "ra-input-rich-text";

interface DealsFormInputsProps {
  isEdit?: boolean;
  isCreate?: boolean;
}

export const DealsFormInputs: FunctionComponent<DealsFormInputsProps> = (props: DealsFormInputsProps) => {
  const { isCreate, isEdit } = props;
  const { save: saveOnEdit } = useEditContext();
  const { save: saveOnCreate } = useCreateContext();

  const transformData = (data) => {
    const { clickOption, ...restParams } = data;

    if (data.type !== DealType.Code) {
      return { url: clickOption, ...restParams };
    }

    return { code: clickOption, ...restParams };
  };

  const onSubmit = (data) => {
    const modifiedData = transformData(data);

    if (isEdit) {
      saveOnEdit(modifiedData);
      return;
    }

    if (isCreate) {
      saveOnCreate(modifiedData);
      return;
    }
  };

  return (
    <SimpleForm onSubmit={onSubmit}>
      <TextInput validate={required()} source="title" />
      <TextInput validate={required()} source="imageRedirectUrl" />
      <RichTextInput sx={REFERENCE_ARRAY_INPUT_SX} validate={required()} source="description" />
      <SelectInput
        disabled={isEdit}
        validate={required()}
        source="type"
        choices={DEAL_TYPE_SELECT_OPTIONS}
        optionValue="name"
      />
      <ClickOptionInput />
      <ImageInput source="image" label="Deal image" validate={isCreate && required()}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <OptionalInputs />
    </SimpleForm>
  );
};
